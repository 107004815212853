// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-screens-about-us-index-js": () => import("./../../../src/screens/AboutUs/index.js" /* webpackChunkName: "component---src-screens-about-us-index-js" */),
  "component---src-screens-activities-index-js": () => import("./../../../src/screens/Activities/index.js" /* webpackChunkName: "component---src-screens-activities-index-js" */),
  "component---src-screens-imprint-index-js": () => import("./../../../src/screens/Imprint/index.js" /* webpackChunkName: "component---src-screens-imprint-index-js" */),
  "component---src-screens-index-index-js": () => import("./../../../src/screens/Index/index.js" /* webpackChunkName: "component---src-screens-index-index-js" */),
  "component---src-screens-policy-index-js": () => import("./../../../src/screens/Policy/index.js" /* webpackChunkName: "component---src-screens-policy-index-js" */),
  "component---src-screens-references-index-js": () => import("./../../../src/screens/References/index.js" /* webpackChunkName: "component---src-screens-references-index-js" */)
}

